<template>
  <b-overlay
    :show="$store.state.app.isContentLoading"
    spinner-variant="primary"
    spinner-type="grow"
    rounded="sm"
  >

    <b-card
      :title="$t('Tag')"
    >

      <!-- BODY -->
      <validation-observer
        #default="{ handleSubmit }"
        ref="refFormObserver"
      >
        <!-- Form -->
        <b-form
          @submit.prevent="handleSubmit(onSubmit)"
          @reset.prevent="resetForm"
        >

          <b-row>
            <!-- Field: Tag Name -->
            <b-col
              md="4"
            >
              <validation-provider
                #default="validationContext"
                name="name"
                rules="required"
              >
                <b-form-group
                  :label="$t('Name')"
                  label-for="name"
                >
                  <b-form-input
                    id="name"
                    v-model="tagData.name"
                    :state="getValidationState(validationContext)"
                    trim
                    placeholder=""
                    @keydown.space.prevent
                  />

                  <b-form-invalid-feedback>
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>

            <!-- Field: Tag Description -->
            <b-col
              md="4"
            >
              <validation-provider
                #default="validationContext"
                name="description"
                rules="required"
              >
                <b-form-group
                  :label="$t('Description')"
                  label-for="description"
                >
                  <b-form-input
                    id="description"
                    v-model="tagData.description"
                    :state="getValidationState(validationContext)"
                    trim
                    placeholder=""
                  />

                  <b-form-invalid-feedback>
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>

          </b-row>

          <!-- Form Actions -->
          <div class="d-flex mt-2">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-2"
              type="submit"
            >
              <feather-icon icon="SaveIcon" />&nbsp;
              {{ $t('Save') }}
            </b-button>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="button"
              variant="outline-secondary"
              @click="cancel"
            >
              <feather-icon icon="XIcon" />&nbsp;
              {{ $t('Cancel') }}
            </b-button>
          </div>

        </b-form>

      </validation-observer>

    </b-card>
  </b-overlay>
</template>

<script>

import {
  BOverlay, BRow, BCol, BCard, BForm, BFormGroup, BFormInput, BFormInvalidFeedback, BButton,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { ref, onUnmounted } from '@vue/composition-api'
import { required } from '@validations'
import formValidation from '@core/comp-functions/forms/form-validation'
import Ripple from 'vue-ripple-directive'
import store from '@/store'
import tagStoreModule from '../tagStoreModule'

export default {
  components: {
    BOverlay,
    BCard,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BButton,
    BRow,
    BCol,

    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  model: {

  },
  data() {
    return {
      required,
    }
  },
  computed: {
  },
  methods: {
    cancel() {
      this.$router.push({ name: 'tag-list' })
    },
  },
  setup(props, context) {
    const STORE_MODULE_NAME = 'tag'

    // Register module
    if (!store.hasModule(STORE_MODULE_NAME)) store.registerModule(STORE_MODULE_NAME, tagStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(STORE_MODULE_NAME)) store.unregisterModule(STORE_MODULE_NAME)
    })

    const blankData = {
      name: '',
    }
    const router = context.root.$router
    const tagData = ref(JSON.parse(JSON.stringify(blankData)))
    const onSubmit = () => {
      const data = JSON.parse(JSON.stringify(tagData.value))
      store.dispatch('tag/add', data)
        .then(response => {
          if (response.status === 201) {
            router.push({ name: 'tag-list' })
          } else {
            console.log(response)
          }
        })
        .catch(error => {
          window.swal('Error', JSON.stringify(error.response.data).replace(/"([^"]+)":/g, '$1:'))
        })
    }

    const {
      refFormObserver,
      getValidationState,
    } = formValidation()

    return {
      tagData,
      onSubmit,

      refFormObserver,
      getValidationState,
    }
  },
}
</script>
